import {Component, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/ms/admin/service/admin.service';
import { AuthenticationService } from 'src/app/ms/oauth2/service/authentication.service';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {
  admin: any;

  constructor(
    private authenticationService: AuthenticationService,
    private adminService: AdminService

    
    ) {  }

  ngOnInit() { 
    if (this.authenticationService.currentUserValue.roles[0] == "ADMIN"){
      this.adminService.getAdmin().subscribe((data) =>{
        this.admin = data;
    });
    }
    
  }
  logout() {
    this.authenticationService.logout();
  }
}
